import React, { useEffect, useState } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function Home() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50; // Vous pouvez ajuster la valeur en fonction de la distance de défilement que vous souhaitez.
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const handleContactByEmail = () => {
    const email = 'mariecadieuxpro@gmail.com';
    const subject = 'Question/Demo Inquiry';
    const body = 'Bonjour, \n\nJe souhaite vous poser une question / planifier une démo.';

    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email client with the pre-filled email
    window.location.href = mailtoUrl;
  };

  return (
    <div className={`PrincipalDiv ${scrolled ? 'scrolled' : ''}`}>
      <header className={scrolled ? 'scrolled' : ''}>
      <div className="video-container">
        <video id="video" autoPlay muted>
          <source src="4K_f9f9f9.mp4" type="video/mp4" />
          {/* Ajoutez différentes sources pour les différents formats de vidéos si nécessaire */}
          Votre navigateur ne supporte pas la lecture de la vidéo.
        </video> 
     
      </div>
      <nav>
        <ul>
          <li>
            <a href="/">Accueil</a>
          </li>
          <li>
            <a href="#CarrouselDiv">Fonctionnalités</a>
          </li>
          <li>
            <a href="#pricing">Abonnement</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>
    </header>
      <h1>Bienvenue sur Dataction</h1>
      <div className="home__buttons">
      <Link to="https://datactionapi.web.app/" className="home__button">
          S'inscrire
        </Link>
        {" "} 
        <Link to="https://dataction.app/Login" className="home__button">
          Se connecter
        </Link>
      </div>
    
      {/* <div className='Features' id='Features'>
    
      <ul >
  <div className ="SectionsFeatures">
  <li>Suivi de paiement client</li>
  <img src="DashImage1.jpg"></img>
  </div>
  <br></br>
  <div className ="SectionsFeatures">
  <li>Suivi de paiement prestataire</li>
  <img src="DashImage2.jpg"></img>
  </div>
  <br></br>
  <div className ="SectionsFeatures">
  <li>Analyse de données</li>
  <img src="DashImage3.jpg"></img>
  </div>
  <br></br>
  <div className ="SectionsFeatures">
  <li>Atteinte d'objectifs</li>
  <img src="DashImage4.jpg"></img>
  </div>
</ul>
      </div> */}
      <div className="SectionsFeatures">
  <div>
    <li>Suivi de paiement client</li>
    <img src="DashImage1.jpg" alt="Image 1" />
  </div>
  <div>
    <li>Suivi de paiement prestataire</li>
    <img src="DashImage2.jpg" alt="Image 2" />
  </div>
  <div>
    <li>Analyse de données</li>
    <img src="DashImage3.jpg" alt="Image 3" />
  </div>
  <div>
    <li>Atteinte d'objectifs</li>
    <img src="DashImage4.jpg" alt="Image 4" />
  </div>
</div>
<br></br>
<h1> Regardons tout ça en image </h1>
      <div className='CarrouselDiv' id='CarrouselDiv'>
      <Carousel 
      showArrows={true} 
      showThumbs={true} 
      infiniteLoop={true} 
      className="carousel"
      autoPlay={true} // Propriété pour activer le défilement automatique
      interval={4000} // Intervalle entre chaque diapositive (en millisecondes)
      transitionTime={1500}
      >
        <div>
          <img src="DashPresta.png" alt="Image 1" />
        </div>
        <div>
          <img src="Objectifs.png" alt="Image 2" />
        </div>
        <div>
          <img src="Graphique.png" alt="Image 2" />
        </div>
        <div>
          <img src="PaiementsClients.png" alt="Image 2" />
        </div>
        {/* Ajoutez d'autres images selon vos besoins */}
      </Carousel>
      </div>
   <div id="pricing" className='pricing'>
   <h1> 2 solutions d'abonnement :</h1>
   <div className="home__buttons__Pricing">
      <Link to="https://datactionapi.web.app/" className="home__button__Pricing">
              Découvrir les solutions
        </Link>       
        </div>
   <h2>Sans engagement, votre plan mensuel et votre plan annuel vous laissent toute la liberté de vous désinscrire à tout moment </h2>

      <div className="pricing-plans">

        {/* Add more pricing plans as needed */}
      </div>
   </div>
   <br></br>
   {/* <div id="contact" className='contact-container'>
   <h1>Une question ? Envie d'une démo ? </h1>
      <p>N'hésitez pas à contacter l'équipe support:</p>
      <div className="contact-form">
        <label className="form-label">Nom:</label>
        <input className="form-input" type="text" placeholder="Votre nom" />

        <label className="form-label">Email:</label>
        <input className="form-input" type="email" placeholder="Votre adresse email" />

        <label className="form-label">Message:</label>
        <textarea className="form-textarea" rows="4" placeholder="Votre message"></textarea>

        <button className="form-submit">Envoyer le Message</button>
      </div>
      <br></br>
   </div> */}
<div id="contact" className='contact-container'>
   <button className="form-submit" onClick={handleContactByEmail}>
            Nous contacter par mail
          </button>
          <br></br>
          </div>
    </div>
  );
}

export default Home;