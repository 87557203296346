import React from 'react';
// import './CGU.css'; // Assurez-vous que le fichier CSS est dans le même répertoire que ce composant

function CGU() {
  return (
    <div className="cgu-container">
      <h1>Conditions Générales d'Utilisation</h1>
      <p className="update-date">Date de la dernière mise à jour : 09/09/2024</p>
      <p>
        Bienvenue sur Dataction!
      </p>
      <p>
        Veuillez lire attentivement les présentes Conditions Générales d'Utilisation (ci-après "CGU") avant d'utiliser notre application Dataction (ci-après "l'Application"). En utilisant l'Application, vous acceptez d'être lié par ces CGU. Si vous n'acceptez pas ces CGU, veuillez ne pas utiliser l'Application.
      </p>
      <h2>1. Utilisation de l'Application</h2>
      <p>
        1.1 Vous devez avoir au moins 18 ans pour utiliser l'Application.
      </p>
      <p>
        1.2 Vous êtes seul responsable de toute utilisation de votre compte et de votre mot de passe. Assurez-vous de maintenir la confidentialité de ces informations.
      </p>
      <p>
        1.3 Vous acceptez de ne pas utiliser l'Application à des fins illégales ou interdites par la loi.
      </p>
      <h2>2. Contenu de l'Utilisateur</h2>
      <p>
        2.1 Vous êtes responsable de tout contenu que vous publiez ou téléchargez sur l'Application (ci-après "Contenu de l'Utilisateur").
      </p>
      <p>
        2.2 Vous garantissez que tout Contenu de l'Utilisateur que vous publiez respecte les lois en vigueur et ne viole pas les droits d'autrui, y compris les droits d'auteur, les marques de commerce, la vie privée, ou tout autre droit.
      </p>
      <p>
        2.3 Nous nous réservons le droit de supprimer tout Contenu de l'Utilisateur qui viole ces CGU ou qui est considéré inapproprié.
      </p>
      <h2>3. Propriété Intellectuelle</h2>
      <p>
        3.1 L'Application et tout son contenu, y compris, mais sans s'y limiter, les textes, graphiques, logos, images, clips audio, clips vidéo, données, informations et logiciels, sont la propriété de l'entreprise Dataction et sont protégés par les lois sur le droit d'auteur et d'autres lois sur la propriété intellectuelle.
      </p>
      <p>
        3.2 Vous acceptez de ne pas reproduire, distribuer, modifier, créer des œuvres dérivées, afficher publiquement, exécuter publiquement, republier, télécharger, stocker ou transmettre tout contenu de l'Application, sauf dans les limites expressément autorisées par ces CGU.
      </p>
      <h2>4. Limitation de Responsabilité</h2>
      <p>
        4.1 L'Application est fournie "telle quelle" et "telle que disponible" sans garantie d'aucune sorte, expresse ou implicite.
      </p>
      <p>
        4.2 Nous ne serons en aucun cas responsables des dommages directs, indirects, spéciaux, consécutifs ou punitifs résultant de l'utilisation de l'Application ou de son contenu.
      </p>
      <h2>5. Modification des CGU</h2>
      <p>
        5.1 Nous nous réservons le droit de modifier ces CGU à tout moment. Toute modification prendra effet immédiatement dès sa publication sur l'Application.
      </p>
      <h2>6. Contact</h2>
      <p>
        6.1 Si vous avez des questions ou des préoccupations concernant ces CGU, veuillez nous contacter à <a href="mailto:mariecadieuxpro@gmail.com">mariecadieuxpro@gmail.com</a>.
      </p>
    </div>
  );
}

export default CGU;
