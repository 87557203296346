import React from 'react';
import { Link } from 'react-router-dom'; // N'enveloppez pas Link dans Router ici
import './Footer.css';

function Footer() {
  return (
    <footer>
      <nav>
        <ul>
          <li>
            <Link to="/CGU">CGU</Link>
          </li>
          <li>
            <Link to="https://www.instagram.com/dataction.app/">Instagram</Link>
          </li>
          <li>
          <a className="footer__nav-freepik" href="http://www.freepik.com">Images designed by Freepik</a>
          </li>
          {/* <li>
            <Link to="/pricing">Prix</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li> */}
        </ul>
      </nav>
    </footer>
  );
}

export default Footer;