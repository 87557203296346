// // src/App.js
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './components/Header';
// import Home from './pages/Home';
// import Features from './pages/Features';
// import Pricing from './pages/Pricing';
// import Contact from './pages/Contact';

// function App() {
//   return (
//       <div className="app">
//             <Header />
//         <Router>
//           <Routes>
//           <Route exact path="/" element={<Home />} />
//           <Route exact path="/features" element={<Features />} />
//           <Route exact path="/pricing" element={<Pricing />} />
//           <Route exact path="/contact" element={<Contact />} />
//           </Routes>
//         </Router>
//       </div>
//   );
// }

// export default App;



// import React from "react";
// import Signup from "./components/Signup";
// import Login from "./components/Login";
// import Dashboard from "./components/Dashboard";
// import PrivateRoute from "./components/PrivateRoute";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { AuthProvider } from "./contexts/AuthContext";

// // function App() {
// //   return (
// //     <Router>

// //       <div className="App">
// //         <nav className="navbar navbar-expand-lg navbar-light fixed-top">
// //           <div className="container">
// //             <Link className="navbar-brand" to={"/sign-in"}>
// //               positronX
// //             </Link>
// //             <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
// //               <ul className="navbar-nav ml-auto">
// //                 <li className="nav-item">
// //                   <Link className="nav-link" to={"/sign-in"}>
// //                     Login
// //                   </Link>
// //                 </li>
// //                 <li className="nav-item">
// //                   <Link className="nav-link" to={"/Signup"}>
// //                     Sign up
// //                   </Link>
// //                 </li>
// //               </ul>
// //             </div>
// //           </div>
// //         </nav>
// //         <div className="auth-wrapper">
// //           <div className="auth-inner">
// //             <Routes>
// //               <Route exact path="/" element={<Login />} />
// //               <Route path="/sign-in" element={<Login />} />
// //               <Route path="/Signup" element={<Signup />} />
// //               <PrivateRoute exact path="/" component={Dashboard} />
// //             </Routes>
// //           </div>
// //         </div>
// //       </div>
// //     </Router>
// //   );
// // }
// // export default App;

// function App() {
//   return (
//     <div className="App">
//       <Router>
//         <AuthProvider>
//           <Routes>
//             <PrivateRoute exact path="/" component={Dashboard} />
//             <Route path="/signup" component={Signup} />
//             <Route path="/login" component={Login} />
//           </Routes>
//         </AuthProvider>
//       </Router>
//     </div>
//   );
// }

// export default App;

// // Ce code définit le composant App qui rend un composant Router de react-router-dom et contient un composant Route.
// // Le composant Route est utilisé pour afficher un composant spécifique en fonction de l'URL actuelle. Dans ce cas, la route "/login" est associée au composant Login, qui sera rendu lorsque l'utilisateur accède à l'URL "/login".
// // Le exact prop signifie que le chemin "/login" doit correspondre exactement pour que le composant Login soit rendu. Si le chemin "/login" correspondait également à une autre route, cette autre route serait affichée à la place.
// // Le composant App peut être considéré comme un point d'entrée pour l'application, où les différentes routes sont définies et les composants sont rendus en fonction de l'URL actuelle.

import "./App.css";
import {  Route, Routes } from "react-router-dom";
// import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
// import Features from './pages/Features';
// import Pricing from './pages/Pricing';
// import Contact from './pages/Contact'; 
import CGU from './pages/CGU';
function App() {
  return (
    <div className="app">
         {/* <Header /> */}
          <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/features" element={<Features />} />
          // <Route exact path="/pricing" element={<Pricing />} /> */}
          {/* // <Route exact path="/contact" element={<Contact />} /> */}
          <Route exact path="/CGU" element={<CGU />} />
          </Routes>
          <Footer />
    </div>
  );
}
export default App;
